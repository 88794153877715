import { isAfter, isSameDay } from 'date-fns'
import React, {
  ReactElement,
  ReactNode,
  UIEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import typesAPI from '@api/passengerTypes'
import ConnectionFilters from '@components/ConnectionFilters'
import SkeletonFilter from '@components/ConnectionFilters/Skeleton'
import ErrorBoundary from '@components/ErrorBoundary'
import JourneyCard from '@components/JourneyCard'
import SeatsModal, { SeatSelectionData } from '@components/JourneyCard/SeatsModal'
import AlternativeTrip from '@components/JourneyList/AlternativeTrip'
import Emission from '@components/JourneyList/Emission'
import Header from '@components/JourneyList/Header'
import useAlternativeTrips from '@components/JourneyList/hooks/useAlternativeTrips'
import useCheckoutUrl from '@components/JourneyList/hooks/useCheckoutUrl'
import useConnectionsSearchParams from '@components/JourneyList/hooks/useConnectionsSearchParams'
import RetrySkeleton from '@components/JourneyList/Retry/Skeleton'
import ScrollButton from '@components/JourneyList/ScrollButton'
import TransportTabs from '@components/JourneyList/TransportTabs'
import OutboundTrip from '@components/OutboundTrip'
import VirtualList from '@components/VirtualList'
import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'
import useNavigate from '@hooks/useNavigate'
import useSearchParams from '@hooks/useSearchParams'
import { SeatsList } from '@hooks/useSeatsController'
import amplitude from '@lib/analytics/amplitude'
import GTMDataLayer from '@lib/analytics/gtm/GTMdataLayer'
import bem from '@lib/bem'
import connectionUtils, { Emission as EmissionType } from '@lib/connection'
import filtering from '@lib/connections/filtering'
import { FilterOptions, TripDirection } from '@lib/connections/filters/types'
import date from '@lib/date'
import groupConnections from '@lib/groupConnections'
import { Observable } from '@lib/observable'
import passengerUtils from '@lib/passengers'
import seatUtils from '@lib/seatSelection'
import sort from '@lib/sorting'
import { useConnectionsLoader } from '@loaders/connections'
import { useLocationName } from '@loaders/locations'
import { useMediaLoader } from '@loaders/media'
import ScrollContext from '@pages/SearchResult/ScrollContext/scrollContext'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useConnectionFilters } from '@stores/connectionFilters'
import { useConnections } from '@stores/connections'
import { useParams } from '@stores/params'
import { Card, ErrorMessage, Skeleton } from '@ui'

import '@components/JourneyList/index.scss'

interface JourneyListProps {
  children?: ReactNode
}

const filterFares = (connection: Connection, fareClassCode: string): Connection => ({
  ...connection,
  fares: connection.fares.filter(fare => fare.fareClass.code === fareClassCode),
})

const JourneyList = ({ children }: JourneyListProps): ReactElement => {
  const [params] = useParams()
  const { locale, departureLocation, arrivalLocation } = params

  const [
    { fareClasses, seatSelection, checkout, filter, quickReservation, transportationMode, media, passengerTypesList },
  ] = useSettings()

  const [{ outbounds, inbounds }, setConnections] = useConnections()
  const [isInitialSearch, setIsInitialSearch] = useState(true)
  const { data: departure } = useLocationName({ locale, location: departureLocation })
  const { data: arrival } = useLocationName({ locale, location: arrivalLocation })
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const [queryParams, setQueryParams] = useSearchParams()

  const [outboundOpened, setOutboundOpened] = useState<boolean>(false)
  const [outbound, setOutbound] = useState<Connection | null>(null)
  const [outboundPriceWithSeats, setOutboundPriceWithSeats] = useState<Money | null>(null)
  const [currentConnection, setCurrentConnection] = useState<Connection | null>(null)
  const [fareClassCode, setFareClassCode] = useState<string | null>(params.fareClass)
  const [isFiltersModalOpened, setIsFiltersModalOpened] = useState<boolean>(false)
  const [isConnectionsLoading, setIsConnectionsLoading] = useState<boolean>(false)
  const [type, setType] = useState<ConnectionType>('outbound')
  const [filters, , { reset: resetFilters }] = useConnectionFilters()

  const isLocationLoaded = !!departure && !!arrival
  const isMediaEnabled = media.enabled && (media.displayOn === 'search_results' || media.displayOn === 'everywhere')

  const connectionsParams = useConnectionsSearchParams()
  const { data, isLoading, errorCode } = useConnectionsLoader(connectionsParams)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const groupedConnections = useMemo(() => data && groupConnections(data, params), [data])
  useEffect(() => {
    const connections = groupedConnections ?? { outbounds: [], inbounds: [] }
    setConnections(connections)
    if (groupedConnections) {
      const connectionsCount = Object.values(connections).flat().length
      GTMDataLayer.pushConnectionResultsEvent({ ...params, connectionsCount })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedConnections, setConnections])

  useEffect(() => {
    setIsConnectionsLoading(isLoading)
  }, [isLoading])

  const alternativeTrips = useAlternativeTrips({
    data: groupedConnections,
    params: connectionsParams,
    error: errorCode,
  })

  const isContentLoading = isConnectionsLoading || alternativeTrips.isLoading || isLoading

  useEffect(() => {
    if (isLocationLoaded && isInitialSearch && data) {
      amplitude.results.load({ departure, arrival, isInitialSearch, resultCount: data.length })
      setIsInitialSearch(false)
    }
    // eslint-disable-next-line
  }, [isLocationLoaded, data])

  useEffect(() => {
    setFareClassCode(params.fareClass)
  }, [params.fareClass])

  const isLocalScroll = isMobile || isTablet
  const scrollToTop = () => {
    const ref = isLocalScroll ? listRef : scrollRef
    // istanbul ignore else
    if (ref?.current) {
      ref.current.style.overflow = 'hidden' // stops scroll inertia on touch screens
      ref.current.scrollTo({ top: 0 })
      requestAnimationFrame(() => {
        ref.current?.style.removeProperty('overflow')
      })
    }
  }

  const submitSeats = (data: SeatSelectionData) => {
    const fareClassCode = data.seats[0][0].fareClass as string
    setFareClassCode(fareClassCode)
    void handleConnectionFlow(currentConnection, fareClassCode, data)
  }

  const { data: mediaData } = useMediaLoader({
    enabled: isMediaEnabled,
    params: { marketingCarrierCode: params.marketingCarrierCode },
  })

  const twoWayConnections = useMemo(() => params.returnDate != null, [params.returnDate])

  const listRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const outboundRef = useRef<HTMLDivElement>(null)

  const filterInbounds = useCallback(
    (connections: Connection[]): Connection[] => {
      const outboundArrivalTime = new Date((outbound as Connection).arrivalTime)
      const returnDate = date.parse(params.returnDate as string)

      const isInbound = (c: Connection): boolean =>
        isAfter(new Date(c.departureTime), outboundArrivalTime) &&
        isSameDay(date.parse(c.departureTime, 'UTC'), returnDate) &&
        c.departureStation.code === outbound?.arrivalStation.code &&
        c.arrivalStation.code === outbound?.departureStation.code

      return connections.reduce<Connection[]>((availableConnections, connection) => {
        if (!isInbound(connection)) return availableConnections
        const filteredConnection =
          quickReservation.enabled || !fareClassCode ? connection : filterFares(connection, fareClassCode)
        if (filteredConnection.fares.length) availableConnections.push(filteredConnection)

        return availableConnections
      }, [])
    },
    [fareClassCode, outbound, quickReservation.enabled, params.returnDate],
  )

  const connections = useMemo<Connection[]>(
    () => (type === 'outbound' ? outbounds : filterInbounds(inbounds)),
    [filterInbounds, outbounds, inbounds, type],
  )
  const filterOptions = useMemo<FilterOptions>(
    () => ({ byCheapest: !['everywhere', 'search_results'].includes(fareClasses.displayOn) }),
    [fareClasses.displayOn],
  )
  const transformedList = useMemo(() => {
    const data = filtering.applyFilters(connections, filters, filterOptions)

    return sort.sortConnectionList(data, params.sorting)
  }, [connections, filterOptions, filters, params.sorting])

  const buildRedirectUrl = useCheckoutUrl()
  const navigate = useNavigate()
  const [, , { reset: resetCheckout }] = useCheckout()
  const [outboundSeats, setOutboundSeats] = useState<SeatsList>([])

  const handleConnectionFlow = async (
    connection: Connection | null,
    selectedFare: string,
    { passengerCard, seats }: SeatSelectionData = { seats: [] },
  ): Promise<void> => {
    connection && amplitude.results.clickTrip(connection, selectedFare, transformedList)
    let passengers = params.passengers
    if (passengerCard !== undefined) {
      const cards = passengerCard
        ? [{ ...params.passengers?.[0].cards?.[0], name: passengerCard.name }]
        : /* istanbul ignore next */ []
      passengers = params.passengers?.[0] ? [{ ...params.passengers?.[0], cards }] : /* istanbul ignore next */ null
    }

    if (twoWayConnections && !outbound) {
      if (passengerCard !== undefined) setQueryParams({ ...queryParams, passengers })
      scrollToTop()
      setOutboundOpened(true)
      setOutbound(connection)
      setType('inbound')
      setFareClassCode(selectedFare)
      setCurrentConnection(null)
      setOutboundSeats(seats)
      currentConnection &&
        Object.values(seats).length > 0 &&
        setOutboundPriceWithSeats(seatUtils.sumOutboundPrice(seats, currentConnection, params.currency))
    } else {
      // istanbul ignore next
      if (!connection) return

      const selectedTrip = {
        connection,
        fareClassCode: selectedFare,
        seats,
      }
      const previouslySelectedTrip = {
        connection: outbound ?? connection,
        fareClassCode: fareClassCode ?? selectedFare,
        seats: outboundSeats,
      }

      const params = {
        outbound: twoWayConnections ? previouslySelectedTrip : selectedTrip,
        inbound: twoWayConnections ? selectedTrip : null,
        passengers,
        passengerTypes:
          passengerTypesList.enabled && passengerTypesList.multiple
            ? await typesAPI.load({ marketingCarrierCode: connection.marketingCarrier.code }).catch(() => null)
            : null,
      }

      const checkoutUrl = buildRedirectUrl(params)

      if (checkoutUrl.startsWith(location.origin) && checkout.type !== 'express') {
        resetCheckout()
        navigate(checkoutUrl.split(location.origin)[1])
      } else {
        window.location.href = checkoutUrl
      }
    }
  }

  const handleSelectConnection = (connection: Connection, fareClassCode: string): void => {
    if (seatSelection.enabledOnSearchResults) {
      type === 'outbound' && setFareClassCode(fareClassCode)
      setCurrentConnection(connection)
    } else {
      void handleConnectionFlow(connection, fareClassCode)
    }
  }

  const handleClickDetailsAmplitude = (connection: Connection): void => {
    amplitude.results.clickDetails(connection, transformedList)
  }

  const resetConnection = (): void => {
    setOutboundOpened(false)
    setType('outbound')
    setCurrentConnection(null)
    setOutboundSeats([])

    outboundRef.current?.addEventListener(
      'animationend',
      event => {
        ;(event.target as HTMLElement).style.display = 'none'
        setOutbound(null)
        setFareClassCode(null)
      },
      { once: true },
    )
  }

  const { scrollObservable: outerScroll, scrollRef } = useContext(ScrollContext)
  const scrollObservable = useRef(new Observable<UIEvent>())

  useEffect(() => {
    if (!twoWayConnections) resetConnection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoWayConnections])

  useEffect(() => {
    const updatedOutbound = outbounds.find(item => item.id === outbound?.id)
    if (updatedOutbound) setOutbound(updatedOutbound)
    setOutboundOpened(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outbounds])

  useEffect(() => {
    resetFilters(['transportMode'])
  }, [connections, resetFilters])

  const closeFiltersModal = useCallback(() => {
    setIsFiltersModalOpened(false)
  }, [])

  const renderJourneyCard = (connection: Connection): ReactNode => (
    <JourneyCard
      connection={connection}
      onClick={handleSelectConnection}
      selectedFareClass={fareClassCode}
      onDetailsOpen={handleClickDetailsAmplitude}
      isInbound={type === 'inbound'}
      disableFareFilter={quickReservation.enabled}
      media={mediaData}
    />
  )

  const renderEmission = (emission: EmissionType): ReactNode => (
    <div className="journey-list__emission-card">
      <Card body={<Emission position="bottom-end" {...emission} />} />
    </div>
  )

  const renderElement = (item: Connection | EmissionType): ReactNode => {
    if ('typicalEmission' in item) return renderEmission(item)

    return renderJourneyCard(item)
  }

  const virtualListItems = useMemo(() => {
    const emission = connectionUtils.calculateEmission(transformedList)
    const POSITION = 1

    if (!emission) return transformedList

    return [...transformedList.slice(0, POSITION), emission, ...transformedList.slice(POSITION)]
  }, [transformedList])

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    scrollObservable.current.set(event)
  }
  const tripDirection = useMemo<TripDirection>(() => {
    if (params.returnDate == null) return 'oneWay'
    return type === 'outbound' ? 'outbound' : 'inbound'
  }, [params.returnDate, type])

  return (
    <ScrollContext.Provider
      value={{
        scrollRef: isLocalScroll ? listRef : scrollRef,
        scrollObservable: isLocalScroll ? scrollObservable.current : outerScroll,
      }}
    >
      <ScrollButton listRef={listRef} onClick={scrollToTop} />
      <div ref={listRef} className="journey-list column" onScroll={onScroll}>
        {outbound && fareClassCode && (
          <OutboundTrip
            ref={outboundRef}
            connection={outbound}
            fareClassCode={fareClassCode}
            onClick={resetConnection}
            opened={outboundOpened}
            seatsPrice={outboundPriceWithSeats}
            isAmendment={!!params.bookingId}
          />
        )}
        <div className="row-lg column">
          {isLocalScroll && <div className={bem('journey-list', 'backdrop')} />}
          {!errorCode && filter.enabled && (
            <ErrorBoundary fallback={() => <></>}>
              <div className="journey-list__filters column">
                {isContentLoading && !isMobile && <SkeletonFilter />}
                {!isContentLoading && (
                  <ConnectionFilters
                    tripDirection={tripDirection}
                    connections={connections}
                    foundConnectionsCount={transformedList.length}
                    opened={isFiltersModalOpened}
                    onClose={closeFiltersModal}
                    options={filterOptions}
                  />
                )}
              </div>
            </ErrorBoundary>
          )}
          <div className="journey-list__container">
            {transportationMode.enabled && (
              <TransportTabs
                connections={connections}
                transportListType={transportationMode.supported}
                isLoading={isContentLoading}
              />
            )}
            {alternativeTrips.altParams && !isContentLoading && (
              <AlternativeTrip
                altParams={alternativeTrips.altParams}
                departureLocationName={departure?.name}
                arrivalLocationName={arrival?.name}
              />
            )}
            <Header
              ref={titleRef}
              type={type}
              outbound={outbound}
              twoWayConnections={twoWayConnections}
              onClick={() => {
                setIsFiltersModalOpened(true)
              }}
              isLoading={isContentLoading}
            />
            {errorCode && <ErrorMessage code={errorCode} />}
            {!errorCode && (
              <div className="journey-list__cards">
                <Skeleton.List Skeleton={RetrySkeleton} loading={isContentLoading} amount={1}>
                  <VirtualList items={virtualListItems} renderElement={renderElement} gap={25} />
                </Skeleton.List>
                {!transformedList.length && !isContentLoading && <ErrorMessage code="emptyResult" />}
                {seatSelection.enabledOnSearchResults && currentConnection != null && (
                  <SeatsModal
                    onClose={() => setCurrentConnection(null)}
                    connection={currentConnection}
                    onSubmit={submitSeats}
                    fareClassFilter={type === 'inbound' ? fareClassCode : null}
                    initialFareClass={fareClassCode}
                    pax={passengerUtils.getPaxCount(params.passengers)}
                    outboundSeatsCount={outboundSeats?.[0]?.length}
                  />
                )}
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    </ScrollContext.Provider>
  )
}

export default JourneyList
