import { useFormikContext } from 'formik'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { VacancyResponse } from '@api/vacancy'
import ancillaryUtils from '@lib/ancillary'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Vacancy } from '@lib/vacancy'
import { useVacancyLoader } from '@loaders/vacancy'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useVacancyProps } from '@pages/Checkout/hooks/useVacancyProps'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

const SEPARATOR = '-'

const VacancyLoader = (): ReactElement | null => {
  const { t } = useTranslation()
  const [errorCodes, setErrorCodes] = useState<string[]>([])
  const [{ express, bookingId }] = useParams()
  const [
    {
      reservation: { enabled: reservation },
      fareClasses: { displayOn },
    },
  ] = useSettings()
  const {
    values: {
      passengers,
      vacancies,
      fareClass,
      returnFareClass,
      meta: { cards },
    },
    setFieldValue,
  } = useFormikContext<CheckoutFormData>()
  const vacancyProps = useVacancyProps(passengers)
  const fullFareClass = useMemo(() => `${fareClass}${returnFareClass ?? ''}`, [fareClass, returnFareClass])
  const storedVacancy = useMemo(() => vacancies.find(v => v.fareClass === fullFareClass), [fullFareClass, vacancies])
  const isFareClassesEnabled = displayOn === 'checkout' || displayOn === 'everywhere' || !!bookingId
  const disallowFare = isFareClassesEnabled && storedVacancy && !express

  const handleSuccess = useCallback(
    (data: Vacancy | VacancyResponse, carrier: string): void => {
      const vacancy = { ...data, ancillaries: ancillaryUtils.updateSegmentIndex(data.ancillaries, carrier) }
      const filteredVacancies = vacancies.filter(v => v.fareClass !== fullFareClass)

      setFieldValue('vacancy', vacancy)
      !reservation && setFieldValue('price', data.price)
      !reservation && setFieldValue('originalPrice', data.originalPrice)
      !reservation && setFieldValue('fees', data.fees)
      setFieldValue('priceError', null)
      setFieldValue('vacancies', [...filteredVacancies, { ...vacancy, fareClass: fullFareClass }])
    },
    [fullFareClass, reservation, setFieldValue, vacancies],
  )

  const { isLoading, data, errorCode } = useVacancyLoader(
    { ...vacancyProps, cards, bookingId, returnFareClass, fareClass: disallowFare ? null : fareClass },
    {
      onSuccess: (data, { marketingCarrierCode }) => {
        handleSuccess(data, marketingCarrierCode)
      },
      onError: error => {
        const errorCodes =
          Array.isArray(error.data?.errors) && error.data.errors?.map(({ code }) => code.replace(/\./g, SEPARATOR))
        const filteredVacancies = vacancies.filter(v => v.fareClass !== fullFareClass)

        errorCodes && setErrorCodes(errorCodes)
        setFieldValue('priceError', error)
        setFieldValue('vacancy', data)
        setFieldValue('vacancies', [
          ...filteredVacancies,
          { vacant: false, fareClass: fullFareClass, ancillaries: [], appliedCards: [] },
        ])
        !reservation && setFieldValue('price', {})
      },
    },
  )

  useEffect(() => {
    setFieldValue('isVacancyLoading', isLoading)
  }, [setFieldValue, isLoading])

  useEffect(() => {
    if (storedVacancy) handleSuccess(storedVacancy, vacancyProps.marketingCarrierCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fareClass, returnFareClass])

  if (isLoading || reservation || !storedVacancy || (!errorCode && storedVacancy?.vacant)) {
    return null
  }

  const errorMessages = [
    ...new Set(
      (errorCodes.length > 0 ? errorCodes : ['notVacant']).map(code =>
        t(`errors.codes.${code}.title`, { defaultValue: t('checkout.notEnoughSeats') }),
      ),
    ),
  ]

  return (
    <>
      {errorMessages.map(message => (
        <div key={message} className={bem('checkout-form', 'message')}>
          {message}
        </div>
      ))}
    </>
  )
}

export default VacancyLoader
